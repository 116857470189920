<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <div class="d-flex justify-content-between align-items-center px-4 pt-4">
              <h3 class="card-title font-weight-bold" style='font-size: 30px'>Registros</h3>
              <vs-button @click="$router.push('CrearRegistros')">Registrar Movimiento</vs-button>
            </div>
          </template>
          <div class='p-5'>
            <vs-input v-model="search" border placeholder="Search" />
            <div class="table-responsive mt-3">
              <table class="table table-bordered table-responsive-md table-striped text-center">
                <thead>
                  <tr>
                    <th @click.prevent='ordenar("fechaRegistro")' style='max-width: 15rem; cursor: pointer;'>
                      Fecha
                    </th>
                    <th style='cursor: pointer;' @click.prevent='ordenar("descripcion")'>
                      Descripcion
                    </th>
                    <th @click.prevent='ordenar("referencia")' style='cursor: pointer;'>
                      Referencia
                    </th>
                    <th style='cursor: pointer;' @click.prevent='ordenar("importe")'>
                      Importe
                    </th>
                    <th style='max-width: 4rem; cursor: pointer;'>
                      Opciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr :key="i" v-for="(tr, i) in Registro" not-click-selected open-expand-only-td>
                    <td>
                      {{ tr.fecha }}
                    </td>
                    <td>
                      {{ tr.descripcion }}
                    </td>
                    <td>
                      {{ tr.referencia }}
                    </td>
                    <td>
                      {{ tr.importe }}
                    </td>
                    <td>
                      <div class='d-flex justify-content-center' style='gap: .5rem;'>
                        <b-button class='bg-success border-0' v-b-tooltip.hover title='ver'
                          @click="$router.push(`DetallesRegistro/${tr.id}`)">
                          <ion-icon name="eye-outline"></ion-icon>
                        </b-button>
                        <b-button class='bg-primary border-0' title='Editar'
                          @click="$router.push(`EditarRegistro/${tr.id}`)">
                          <ion-icon name="create-outline"></ion-icon>
                        </b-button>
                        <b-button class='bg-danger border-0' title='Eliminar' @click.prevent='deleteRegistro(tr.id)'>
                          <ion-icon name="trash-outline"></ion-icon>
                        </b-button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if='loading' style='height: 10rem;'>
                    <td colspan='5' class='w-100'>Buscando registros</td>
                  </tr>
                  <tr v-else-if='Registro.length === 0' style='height: 10rem;'>
                    <td colspan='8' class='w-100'>No hay Registros</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class='d-flex justify-content-between align-items-center w-100'>
              <b-form-select style='width: 4rem;' placeholder='-- 10 --' :options="options" v-model='max' required
                value='10'>
              </b-form-select>
              <b-pagination v-model="page" :total-rows="totalpagues" :per-page="1"
                aria-controls="my-table"></b-pagination>
            </div>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
  import Swal from 'sweetalert2';
  import { core } from '../../../../../../config/pluginInit';
  // import { debounce } from 'lodash/debounce';

  export default {
    data: () => ({
      Registro: [],
      PersonaId: null,
      search: '',
      page: 1,
      totalpagues: 0,
      max: 10,
      loading: false,
      columna: "",
      ascendente: true,
      options: [
        {
          value: 5,
          text: '5'
        },
        {
          value: 10,
          text: '10'
        }, {
          value: 20,
          text: '20'
        }, {
          value: 50,
          text: '50'
        }, {
          value: 100,
          text: '100'
        }
      ],
    }),
    watch: {
      // search: {
      //   handler: debounce(function () {
      //     this.getData();
      //   }, 700),
      //   immediate: false
      // },
      search() {
        this.getData();
      },
      max() {
        this.getData();
      },
      page() {
        this.getData();
      },
      totalpagues() {
        this.getData();
      },
      columna() {
        this.getData();
      },
      ascendente() {
        this.getData();
      }
    },
    async mounted() {
      const Persona = JSON.parse(localStorage.getItem('credenciales'));
      this.PersonaId = Persona.personaId;

      core.index()
      this.getData();
    },
    methods: {
      async deleteRegistro(id) {
        await Swal.fire({
          title: '¿Estás seguro que deseas eliminar este Movimiento?',
          text: "¡No podrás revertir esto!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, borrarlo!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              await this.$store.dispatch("hl_delete", {
                path: `DEMovimientos/EliminarMovimiento/${id}/persona/${this.PersonaId}`,
              })
              Swal.fire("Listo!", "Movimiento eliminado correctamente.", "success");
              await this.getData();
            } catch (error) {
              Swal.fire("Error", "Hubo un problema al eliminar el Movimiento", "error");
            }
          }
        });
      },
      async getData() {
        this.Registro = [];
        let send = {
          filtro: this.search,
          orden: {
            columna: this.columna,
            ascendente: this.ascendente
          },
          cantDatos: this.max,
          paginacion: this.page
        }
        try {
          this.loading = true;
          const res = await this.$store.dispatch('hl_get', {
            path: `DEMovimientos/ConsultarCantidadMovimientos/${this.PersonaId}?filtro=${this.search}`
          })

          this.totalpagues = Math.ceil(res.data / this.max);
          if (res.data <= this.max) {
            this.page = 1;
          }

          const response = await this.$store.getters.fetchPost({
            path: 'DEMovimientos/MovimientosEmpresa/' + this.PersonaId,
            data: send
          });
          if (response.ok) {
            this.Registro = []
            const data = await response.json();
            if (data.data.length > 0) {
              this.Registro = data.data.map(({ ID, fechaRegistro, descripcion, importe, referencia }) => ({
                id: ID,
                fecha: this.$moment.tz(fechaRegistro, "America/Bogota").format("MM-DD-YYYY"),
                referencia: referencia,
                descripcion: descripcion,
                importe: importe,
              }))
            } else {
              this.Registro = []
            }

          } else {
            console.error('Error en la solicitud:', response.statusText);
          }
        } catch (error) {
          this.Registro = []
        } finally {
          this.loading = false;
        }
      },
      ordenar(columna) {
        if (this.columna === columna) {
          this.ascendente = !this.ascendente;
        } else {
          this.columna = columna;
          this.ascendente = true;
        }
        this.getData();
      }
    },
  }
</script>

<style>
  .vs-input {
    width: 100%;
  }
</style>